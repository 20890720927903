<template>
  <Transition name="fade-up">
    <div v-if="visible" class="process-status" :class="statusType">
      <div class="status-icon" v-html="getStatusIcon"></div>
      <div class="status-content">
        <div class="status-message">{{ message }}</div>
        <div v-if="showProgress" class="progress-bar">
          <div class="progress-fill" :style="{ width: `${progress}%` }"></div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "ProcessStatus",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: "processing", // 'processing', 'success', 'error'
    },
    message: {
      type: String,
      required: true,
    },
    progress: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const statusType = computed(() => ({
      "status-processing": props.status === "processing",
      "status-success": props.status === "success",
      "status-error": props.status === "error",
    }));

    const showProgress = computed(
      () => props.status === "processing" && props.progress > 0
    );

    const getStatusIcon = computed(() => {
      switch (props.status) {
        case "success":
          return `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path d="M20 6L9 17l-5-5"/>
          </svg>`;
        case "error":
          return `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <circle cx="12" cy="12" r="10"/>
            <line x1="15" y1="9" x2="9" y2="15"/>
            <line x1="9" y1="9" x2="15" y2="15"/>
          </svg>`;
        default:
          return `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <circle cx="12" cy="12" r="10"/>
            <path d="M12 6v6l4 2"/>
          </svg>`;
      }
    });

    return {
      statusType,
      showProgress,
      getStatusIcon,
    };
  },
});
</script>

<style scoped lang="scss">
.process-status {
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  padding: 16px 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 16px;
  z-index: 1000;
  min-width: 300px;
  border: 1px solid rgba(255, 255, 255, 0.1);

  &.status-processing {
    .status-icon {
      color: #3b82f6;
      animation: spin 2s linear infinite;
    }
  }

  &.status-success {
    .status-icon {
      color: #22c55e;
    }
  }

  &.status-error {
    .status-icon {
      color: #ef4444;
    }
  }

  .status-icon {
    width: 24px;
    height: 24px;
  }

  .status-content {
    flex: 1;

    .status-message {
      color: white;
      font-size: 14px;
      margin-bottom: 8px;
    }

    .progress-bar {
      height: 4px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 2px;
      overflow: hidden;

      .progress-fill {
        height: 100%;
        background: #3b82f6;
        border-radius: 2px;
        transition: width 0.3s ease;
      }
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fade-up-enter-active,
.fade-up-leave-active {
  transition: all 0.3s ease;
}

.fade-up-enter-from,
.fade-up-leave-to {
  opacity: 0;
  transform: translate(-50%, 20px);
}
</style>
