import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["material-icon", { active: _ctx.isActive }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
  }, [
    _createElementVNode("div", {
      class: "icon-wrapper",
      innerHTML: _ctx.icon
    }, null, 8, _hoisted_1),
    _createElementVNode("div", {
      class: _normalizeClass(["tooltip", { visible: _ctx.showTooltip }])
    }, _toDisplayString(_ctx.name), 3)
  ], 2))
}