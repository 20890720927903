import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "header-inner" }
const _hoisted_3 = { class: "main-content" }
const _hoisted_4 = { class: "grid-container" }
const _hoisted_5 = ["onMouseenter", "onMouseleave"]
const _hoisted_6 = ["src", "alt"]
const _hoisted_7 = { class: "center-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LogoItem = _resolveComponent("LogoItem")!
  const _component_ImageUploader = _resolveComponent("ImageUploader")!
  const _component_ModelViewer = _resolveComponent("ModelViewer")!
  const _component_ProcessStatus = _resolveComponent("ProcessStatus")!

  return (_openBlock(), _createElementBlock("div", {
    class: "app-page",
    onWheel: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleWheel && _ctx.handleWheel(...args)))
  }, [
    _createElementVNode("header", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_LogoItem),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "header-right" }, [
          _createElementVNode("button", { class: "login-btn" }, "Log in"),
          _createElementVNode("button", { class: "menu-btn" }, [
            _createElementVNode("span", { class: "menu-icon" })
          ])
        ], -1))
      ])
    ]),
    _createElementVNode("main", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleImages, (image) => {
          return (_openBlock(), _createElementBlock("div", {
            key: `${image.id}-${image.x}-${image.y}`,
            class: "grid-item",
            style: _normalizeStyle({
            left: `${image.x}px`,
            top: `${image.y}px`,
            width: `${image.width}px`,
            height: `${image.width}px`,
            transform: `translateY(${-_ctx.scrollOffset}px) scale(${
              _ctx.isHovered(image) ? 1.4 : 1
            })`,
            opacity: _ctx.isHovered(image) ? 1 : 0.6,
            zIndex: _ctx.isHovered(image) ? 10 : 1,
          }),
            onMouseenter: ($event: any) => (_ctx.handleItemHover(image, true)),
            onMouseleave: ($event: any) => (_ctx.handleItemHover(image, false))
          }, [
            _createElementVNode("img", {
              src: image.snapUrl,
              alt: `Model ${image.id}`,
              class: "grid-image"
            }, null, 8, _hoisted_6),
            _withDirectives(_createElementVNode("video", {
              ref_for: true,
              ref: (el) => _ctx.setVideoRef(el, image),
              loop: "",
              muted: "",
              class: "grid-video"
            }, null, 512), [
              [_vShow, 
              _ctx.hoveredVideoId === image.id &&
              _ctx.hoveredX === image.x &&
              _ctx.hoveredY === image.y
            ]
            ])
          ], 44, _hoisted_5))
        }), 128)),
        _createVNode(_Transition, { name: "fade" }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", _hoisted_7, [
              _createVNode(_component_ImageUploader, {
                onGenerate: _ctx.handleGenerate,
                onSettings: _ctx.handleSettings,
                "is-loading": _ctx.isLoading,
                hasImage: _ctx.hasImage,
                "onUpdate:hasImage": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.hasImage) = $event))
              }, null, 8, ["onGenerate", "onSettings", "is-loading", "hasImage"])
            ], 512), [
              [_vShow, !_ctx.isScrolled || _ctx.hasImage]
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_ModelViewer, {
          "model-data": _ctx.modelUrl,
          visible: _ctx.showModel,
          "thumbnail-url": _ctx.thumbnailUrl,
          "is-base-model": _ctx.isBaseModel,
          onClose: _ctx.closeViewer,
          onDownload: _ctx.handleDownload
        }, null, 8, ["model-data", "visible", "thumbnail-url", "is-base-model", "onClose", "onDownload"])
      ])
    ]),
    _createElementVNode("div", {
      class: "scroll-progress",
      style: _normalizeStyle({
        width: `${_ctx.scrollProgress}%`,
        opacity: _ctx.isScrolled ? 1 : 0,
      })
    }, null, 4),
    _createVNode(_component_ProcessStatus, {
      visible: _ctx.processStatus.visible,
      status: _ctx.processStatus.status,
      message: _ctx.processStatus.message,
      progress: _ctx.processStatus.progress
    }, null, 8, ["visible", "status", "message", "progress"])
  ], 32))
}