<template>
  <div class="app-page" @wheel="handleWheel">
    <header class="header">
      <div class="header-inner">
        <LogoItem />
        <div class="header-right">
          <button class="login-btn">Log in</button>
          <button class="menu-btn">
            <span class="menu-icon"></span>
          </button>
        </div>
      </div>
    </header>

    <main class="main-content">
      <div class="grid-container">
        <div
          v-for="image in visibleImages"
          :key="`${image.id}-${image.x}-${image.y}`"
          class="grid-item"
          :style="{
            left: `${image.x}px`,
            top: `${image.y}px`,
            width: `${image.width}px`,
            height: `${image.width}px`,
            transform: `translateY(${-scrollOffset}px) scale(${
              isHovered(image) ? 1.4 : 1
            })`,
            opacity: isHovered(image) ? 1 : 0.6,
            zIndex: isHovered(image) ? 10 : 1,
          }"
          @mouseenter="handleItemHover(image, true)"
          @mouseleave="handleItemHover(image, false)"
        >
          <img
            :src="image.snapUrl"
            :alt="`Model ${image.id}`"
            class="grid-image"
          />
          <video
            :ref="(el) => setVideoRef(el, image)"
            loop
            muted
            class="grid-video"
            v-show="
              hoveredVideoId === image.id &&
              hoveredX === image.x &&
              hoveredY === image.y
            "
          ></video>
        </div>

        <transition name="fade">
          <div v-show="!isScrolled || hasImage" class="center-form">
            <ImageUploader
              @generate="handleGenerate"
              @settings="handleSettings"
              :is-loading="isLoading"
              v-model:hasImage="hasImage"
            />
          </div>
        </transition>

        <ModelViewer
          :model-data="modelUrl"
          :visible="showModel"
          :thumbnail-url="thumbnailUrl"
          :is-base-model="isBaseModel"
          @close="closeViewer"
          @download="handleDownload"
        />
      </div>
    </main>

    <div
      class="scroll-progress"
      :style="{
        width: `${scrollProgress}%`,
        opacity: isScrolled ? 1 : 0,
      }"
    ></div>

    <ProcessStatus
      :visible="processStatus.visible"
      :status="processStatus.status"
      :message="processStatus.message"
      :progress="processStatus.progress"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  ref,
  onUnmounted,
  computed,
  watch,
} from "vue";
import LogoItem from "@/components/LogoItem.vue";
import ImageUploader from "@/components/ImageUploader.vue";
import { getAllImages, ImageItem } from "@/mock/images";
import {
  createReconstructionTask,
  queryTaskStatus,
  getUntexturedMesh,
  getTexturedMesh,
} from "@/api/model";
import ModelViewer from "@/components/ModelViewer.vue";
import ProcessStatus from "@/components/ProcessStatus.vue";

interface PositionedImage extends ImageItem {
  x: number;
  y: number;
  width: number;
}

export default defineComponent({
  name: "AppView",
  components: {
    LogoItem,
    ImageUploader,
    ModelViewer,
    ProcessStatus,
  },
  setup() {
    const images = ref<PositionedImage[]>([]);
    const COLUMNS = ref(0);
    const ROWS = ref(0);
    const loadedImages = ref<ImageItem[]>([]);
    const isLoading = ref(false);
    const modelUrl = ref("");
    const showModel = ref(false);
    const thumbnailUrl = ref("");
    const hoveredVideoId = ref<number | null>(null);
    const hoveredX = ref<number | null>(null);
    const hoveredY = ref<number | null>(null);
    const videoRefs = new Map<string, HTMLVideoElement>();
    const imageWidth = ref(200);
    const resizeFlag = ref(Math.random());
    const H_PAD = computed(() => {
      if (resizeFlag.value) {
        // Read resize flag but do nothing
      }
      const n = COLUMNS.value;
      const calculatedPad =
        (window.innerWidth - n * imageWidth.value) / (n - 1);

      const maxPad = imageWidth.value / 2;
      return Math.min(calculatedPad, maxPad);
    });
    const V_PAD = computed(() => {
      if (resizeFlag.value) {
        // Read resize flag but do nothing
      }
      const n = ROWS.value;
      const calculatedPad =
        (window.innerHeight - n * imageWidth.value) / (n - 1);

      const maxPad = imageWidth.value / 2;
      return Math.min(calculatedPad, maxPad);
    });
    let resizeTimeout: number | null = null;
    const isScrolled = ref(false);
    const scrollOffset = ref(0);
    const centerHeight = ref(0);
    // const scrollThreshold = 50;
    const hoveredItem = ref<PositionedImage | null>(null);
    const hCellSize = computed(() => {
      return imageWidth.value + H_PAD.value;
    });
    const vCellSize = computed(() => {
      return imageWidth.value + V_PAD.value;
    });
    const hasImage = ref(false);
    const isBaseModel = ref(false);
    const processStatus = ref({
      visible: false,
      status: "processing",
      message: "",
      progress: 0,
    });

    const scrollProgress = computed(() => {
      if (!isScrolled.value) return 0;

      const maxScroll =
        (images.value.length / COLUMNS.value) *
          (imageWidth.value + V_PAD.value) -
        window.innerHeight;

      return Math.min(100, (scrollOffset.value / maxScroll) * 100);
    });

    const calculateGridDimensions = () => {
      const availableWidth = window.innerWidth;
      const availableHeight = window.innerHeight;

      COLUMNS.value = Math.ceil(availableWidth / (imageWidth.value * 1.5));
      ROWS.value = Math.ceil(availableHeight / (imageWidth.value * 1.5));

      // Ensure minimum grid size
      COLUMNS.value = Math.max(COLUMNS.value, 3);
      ROWS.value = Math.max(ROWS.value, 4);

      // Update column factor
      updateGridFactor(
        COLUMNS.value % 2 === 0 ? 4 : 3,
        ROWS.value % 2 === 0 ? 4 : 3
      );
    };

    const updateGridFactor = (columnFactor: number, rowFactor: number) => {
      const root = document.querySelector(".app-page") as HTMLElement;
      if (root) {
        root.style.setProperty("--column-factor", `${columnFactor}`);
        root.style.setProperty("--row-factor", `${rowFactor}`);
      }
    };

    const calculateImagePositions = () => {
      const root = document.querySelector(".app-page") as HTMLElement;
      if (root) {
        root.style.setProperty("--cell-size", `${hCellSize.value}px`);
        root.style.setProperty("--v-cell-size", `${vCellSize.value}px`);
      }

      // const containerHeight =
      //   document.querySelector(".grid-container")?.clientHeight ?? 1000;
      // const containerWidth =
      //   document.querySelector(".grid-container")?.clientWidth ?? 1000;
      // const yOffset =
      //   (containerHeight -
      //     ROWS.value * (imageWidth.value + PAD.value) +
      //     PAD.value) /
      //   2;
      const yOffset = 72; // Disable vertical offset
      // const xOffset =
      //   containerWidth / 2 -
      //   (COLUMNS.value / 2) * (imageWidth.value + H_PAD.value) +
      //   H_PAD.value / 2;
      const xOffset = 0; // Disable horizontal offset

      // images.value = [];
      images.value.splice(0, images.value.length);

      for (let i = 0; i < COLUMNS.value; i++) {
        for (let j = 0; j < ROWS.value; j++) {
          const image =
            loadedImages.value[
              (i * ROWS.value + j) % loadedImages.value.length
            ];
          images.value.push({
            ...image,
            x: i * (imageWidth.value + H_PAD.value) + xOffset,
            y: j * (imageWidth.value + V_PAD.value) + yOffset,
            width: imageWidth.value,
          });
        }
      }

      if (isScrolled.value) {
        const extraRows = Math.ceil(loadedImages.value.length / COLUMNS.value);
        for (let i = 0; i < COLUMNS.value; i++) {
          for (let j = ROWS.value; j < ROWS.value + extraRows; j++) {
            const image =
              loadedImages.value[
                (i * extraRows + j + ROWS.value * COLUMNS.value) %
                  loadedImages.value.length
              ];
            images.value.push({
              ...image,
              x: i * (imageWidth.value + H_PAD.value) + xOffset,
              y: j * (imageWidth.value + V_PAD.value) + yOffset,
              width: imageWidth.value,
            });
          }
        }
      }
    };

    const calculateImageWidth = () => {
      const width = Math.min(180, Math.max(150, window.innerWidth / 10));
      imageWidth.value = Math.floor(width);
    };

    const handleResize = () => {
      if (resizeTimeout) {
        clearTimeout(resizeTimeout);
      }
      resizeTimeout = setTimeout(() => {
        resizeFlag.value = Math.random();
        calculateImageWidth();
        calculateGridDimensions();
        calculateImagePositions();
      }, 0); // Adjust delay as needed
    };

    onMounted(async () => {
      loadedImages.value = await getAllImages();
      calculateImageWidth();
      calculateGridDimensions();
      calculateImagePositions();
      window.addEventListener("resize", handleResize);

      const centerForm = document.querySelector(".center-form");
      if (centerForm) {
        centerHeight.value = centerForm.clientHeight;
      }
    });

    onUnmounted(() => {
      if (thumbnailUrl.value) {
        URL.revokeObjectURL(thumbnailUrl.value);
      }
      window.removeEventListener("resize", handleResize);
    });

    const handleGenerate = async (file: File) => {
      console.log("Generate with images:", file);
      isLoading.value = true;
      thumbnailUrl.value = URL.createObjectURL(file);

      try {
        // Show initial status
        processStatus.value = {
          visible: true,
          status: "processing",
          message: "Uploading image and creating task...",
          progress: 0,
        };

        // Create reconstruction task
        const taskId = await createReconstructionTask(file);
        let untexturedMeshData = "";
        let texturedMeshData = "";
        let isPolling = true;

        // Poll task status
        while (isPolling) {
          const status = await queryTaskStatus(taskId);

          switch (status) {
            case "MeshGenerating":
              processStatus.value.message = "Generating base mesh model...";
              processStatus.value.progress = 30;
              break;

            case "TextureGenerating":
              if (!untexturedMeshData) {
                processStatus.value.message = "Downloading base mesh model...";
                processStatus.value.progress = 50;
                untexturedMeshData = await getUntexturedMesh(taskId);
                modelUrl.value = untexturedMeshData;
                isBaseModel.value = true;
                showModel.value = true;
              }
              processStatus.value.message = "Generating textured model...";
              processStatus.value.progress = 70;
              break;

            case "Success":
              processStatus.value.message =
                "Downloading final textured model...";
              processStatus.value.progress = 90;
              texturedMeshData = await getTexturedMesh(taskId);
              modelUrl.value = texturedMeshData;
              isBaseModel.value = false;

              processStatus.value.status = "success";
              processStatus.value.message =
                "Model generation completed successfully!";
              processStatus.value.progress = 100;

              setTimeout(() => {
                processStatus.value.visible = false;
              }, 3000);
              isPolling = false;
              break;

            case "Failed":
              throw new Error("Task failed");

            default:
              processStatus.value.message = "Processing...";
          }

          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      } catch (error) {
        console.error("Generation error:", error);
        processStatus.value = {
          visible: true,
          status: "error",
          message: `Error: ${
            error instanceof Error ? error.message : "Unknown error occurred"
          }`,
          progress: 0,
        };

        setTimeout(() => {
          processStatus.value.visible = false;
        }, 5000);
      } finally {
        isLoading.value = false;
      }
    };

    const handleSettings = () => {
      console.log("Open settings");
    };

    const closeViewer = () => {
      showModel.value = false;
    };

    const handleDownload = (url: string) => {
      console.log("Downloading model from:", url);
      // Implement download logic here if needed
      window.open(url, "_blank");
    };

    const setVideoRef = (
      el: HTMLVideoElement | null,
      image: PositionedImage
    ) => {
      const key = `${image.id}-${image.x}-${image.y}`;
      if (el) {
        videoRefs.set(key, el);
      } else {
        videoRefs.delete(key);
      }
    };

    const handleMouseEnter = async (image: PositionedImage) => {
      hoveredVideoId.value = image.id;
      hoveredX.value = image.x;
      hoveredY.value = image.y;

      const key = `${image.id}-${image.x}-${image.y}`;
      const video = videoRefs.get(key);

      if (video && !video.src) {
        video.src = image.url;
        await video.load();
      }

      if (video) {
        video.currentTime = 0;
        video.play().catch((err) => {
          console.warn("Video autoplay failed:", err);
        });
      }
    };

    const handleMouseLeave = (image: PositionedImage) => {
      hoveredVideoId.value = null;
      hoveredX.value = null;
      hoveredY.value = null;

      const key = `${image.id}-${image.x}-${image.y}`;
      const video = videoRefs.get(key);
      if (video) {
        video.pause();
        video.currentTime = 0;
      }
    };

    const handleWheel = (event: WheelEvent) => {
      if (event.deltaY > 0 && !isScrolled.value) {
        isScrolled.value = true;
        scrollOffset.value = 0;
        calculateImagePositions();
      } else if (isScrolled.value) {
        scrollOffset.value = Math.max(
          0,
          Math.min(
            scrollOffset.value + event.deltaY,
            (images.value.length / COLUMNS.value) *
              (imageWidth.value + V_PAD.value) -
              window.innerHeight
          )
        );
        if (scrollOffset.value === 0) {
          isScrolled.value = false;
        }
      }
    };

    const visibleImages = computed(() => {
      const containerHeight =
        document.querySelector(".grid-container")?.clientHeight || 1000;
      const containerWidth =
        document.querySelector(".grid-container")?.clientWidth || 1000;
      const uploaderWidth = hCellSize.value * (COLUMNS.value % 2 === 0 ? 4 : 3);
      const uploaderHeightTemp =
        vCellSize.value * (ROWS.value % 2 === 0 ? 4 : 3);
      const uploaderHeight = Math.min(
        uploaderHeightTemp,
        window.innerHeight * 0.5
      );
      // const uploaderHeight = vCellSize.value * (ROWS.value % 2 === 0 ? 4 : 3);
      const uploaderLeft = containerWidth / 2 - uploaderWidth / 2;
      const uploaderTop = containerHeight / 2 - uploaderHeight / 2;

      // const yOffset =
      //   (containerHeight -
      //     ROWS.value * (imageWidth.value + PAD.value) +
      //     PAD.value) /
      //   2;
      const yOffset = 72; // Disable vertical offset
      // const xOffset =
      //   containerWidth / 2 -
      //   (COLUMNS.value / 2) * (imageWidth.value + H_PAD.value) +
      //   H_PAD.value / 2;
      const xOffset = 0; // Disable horizontal offset

      // Calculate center area dimensions (where uploader is)
      // const centerStartCol = Math.floor(COLUMNS.value / 2) - 1;
      // const centerEndCol = Math.floor(COLUMNS.value / 2) + 1;
      // const centerStartRow = Math.floor(ROWS.value / 2) - 1;
      // const centerEndRow = Math.floor(ROWS.value / 2) + 1;
      const centerStartCol = Math.floor(
        (uploaderLeft + H_PAD.value) / (imageWidth.value + H_PAD.value)
      );
      const centerEndCol = Math.floor(
        (uploaderLeft + uploaderWidth) / (imageWidth.value + H_PAD.value)
      );
      const centerStartRow = Math.floor(
        (uploaderTop + scrollOffset.value + V_PAD.value) /
          (imageWidth.value + V_PAD.value)
      );
      const centerEndRow = Math.floor(
        (uploaderTop + uploaderHeight + scrollOffset.value) /
          (imageWidth.value + V_PAD.value)
      );

      return images.value.filter((img) => {
        // Calculate the actual position considering scroll offset
        const adjustedY = img.y;

        // Calculate grid position
        const imgCol = Math.round(
          (img.x - xOffset) / (imageWidth.value + H_PAD.value)
        );
        const imgRow = Math.round(
          (adjustedY - yOffset) / (imageWidth.value + V_PAD.value)
        );

        // Hide images in the center area if uploader should be visible
        if (!isScrolled.value || hasImage.value) {
          return !(
            imgCol >= centerStartCol &&
            imgCol <= centerEndCol &&
            imgRow >= centerStartRow &&
            imgRow <= centerEndRow
          );
        }

        return true;
      });
    });

    const isHovered = (image: PositionedImage) => {
      return hoveredItem.value === image;
    };

    const handleItemHover = (image: PositionedImage, isEntering: boolean) => {
      hoveredItem.value = isEntering ? image : null;
      if (isEntering) {
        handleMouseEnter(image);
      } else {
        handleMouseLeave(image);
      }
    };

    const updateCellSize = () => {
      const root = document.querySelector(".app-page") as HTMLElement;
      if (root) {
        root.style.setProperty("--cell-size", `${hCellSize.value}px`);
        root.style.setProperty("--v-cell-size", `${vCellSize.value}px`);
      }
    };

    watch(
      hCellSize,
      () => {
        updateCellSize();
      },
      { immediate: true }
    );
    watch(
      vCellSize,
      () => {
        updateCellSize();
      },
      { immediate: true }
    );

    return {
      images,
      handleGenerate,
      handleSettings,
      isLoading,
      modelUrl,
      showModel,
      closeViewer,
      handleDownload,
      handleMouseEnter,
      handleMouseLeave,
      thumbnailUrl,
      hoveredVideoId,
      setVideoRef,
      hoveredX,
      hoveredY,
      isScrolled,
      scrollOffset,
      visibleImages,
      handleWheel,
      isHovered,
      handleItemHover,
      hCellSize,
      hasImage,
      isBaseModel,
      scrollProgress,
      H_PAD,
      V_PAD,
      processStatus,
    };
  },
});
</script>

<style scoped lang="scss">
.app-page {
  --cell-size: 270px;
  --v-cell-size: 210px;
  --column-factor: 3;
  --row-factor: 3;
  min-height: 100vh;
  height: 100vh;
  background: #11110f;
  color: white;
  position: relative;
  overflow: hidden;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: transparent;
  height: 4.5rem;
  margin-inline: auto;
  padding-block: 0;
  padding-inline: 2rem;

  .header-inner {
    backdrop-filter: blur(12px);
    background-color: rgba(17, 17, 15, 0.7);
    border: 1px rgba(255, 255, 255, 0.1) solid;
    border-radius: 36px;
    height: 100%;
    margin-inline: auto;
    margin-top: 1rem;
    max-width: 1536px;
    padding-inline: 2rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-right {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  .login-btn {
    background: rgba(255, 255, 255, 0.1);
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }

  .menu-btn {
    background: transparent;
    border: none;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .menu-icon {
      width: 24px;
      height: 2px;
      background: white;
      position: relative;

      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 24px;
        height: 2px;
        background: white;
        left: 0;
      }

      &::before {
        top: -6px;
      }

      &::after {
        bottom: -6px;
      }
    }
  }
}

.main-content {
  // padding: 80px 2rem 2rem;
  overflow: hidden;
  height: 100vh;
}

.grid-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.grid-item {
  position: absolute;
  border-radius: 12px;
  overflow: hidden;
  opacity: 0.7;
  transition: transform 0.3s ease-out, opacity 0.5s ease-in;
  will-change: transform, opacity;
  animation: fadeIn 0.3s ease-in forwards;

  .grid-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    // filter: brightness(1.1) contrast(0.95) saturate(1.1);
  }

  .grid-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.15s;
    pointer-events: none;
    // filter: brightness(1.1) contrast(0.95) saturate(1.1);

    &[src] {
      opacity: 1;
    }
  }

  &:hover {
    opacity: 1 !important;
    z-index: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.85;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.grid-item {
  position: absolute;
  transition: transform 0.3s ease-out;
}

.center-form {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 30;
  width: calc(var(--column-factor) * var(--cell-size));
  height: calc(var(--row-factor) * var(--v-cell-size));
  max-height: 50vh;
  max-width: 90vw;
  transition: all 0.3s ease;
}

.scroll-progress {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 12px;
  background: linear-gradient(
    var(--rotate, 132deg),
    #5ddcff,
    #3c67e3 43%,
    #4e00c2
  );
  transition: width 0.2s ease, opacity 0.3s ease;
  border-radius: 0 6px 6px 0;
  z-index: 100;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100%;
    background: linear-gradient(
      to right,
      transparent,
      rgba(255, 255, 255, 0.3)
    );
    filter: blur(8px);
    animation: shimmer 10s ease-in-out infinite;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100%;
    background: linear-gradient(
      to right,
      transparent,
      rgba(255, 255, 255, 0.3)
    );
    filter: blur(8px);
    animation: shimmer 10s ease-in-out infinite;
    animation-delay: -5s;
  }
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    transform: translateX(calc(100vw - 100px));
    opacity: 0;
  }
}
</style>
