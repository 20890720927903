import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "status-content" }
const _hoisted_3 = { class: "status-message" }
const _hoisted_4 = {
  key: 0,
  class: "progress-bar"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "fade-up" }, {
    default: _withCtx(() => [
      (_ctx.visible)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["process-status", _ctx.statusType])
          }, [
            _createElementVNode("div", {
              class: "status-icon",
              innerHTML: _ctx.getStatusIcon
            }, null, 8, _hoisted_1),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.message), 1),
              (_ctx.showProgress)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("div", {
                      class: "progress-fill",
                      style: _normalizeStyle({ width: `${_ctx.progress}%` })
                    }, null, 4)
                  ]))
                : _createCommentVNode("", true)
            ])
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}