<template>
  <div class="model-modal" v-if="visible" @click.self="handleClose">
    <div class="modal-content">
      <div class="logo-container">
        <LogoItem :logoOnly="true" :brightness="90" />
      </div>

      <div class="action-buttons">
        <div
          v-for="btn in actionButtons"
          :key="btn.name"
          class="action-btn"
          :title="btn.tooltip"
          @click="handleAction(btn.name)"
          v-html="btn.icon"
        ></div>
      </div>

      <div class="model-viewer" @wheel="handleWheel">
        <canvas ref="canvas" class="model-canvas" v-show="visible"></canvas>

        <!-- 缩略图 -->
        <div class="thumbnail-container" v-if="thumbnailUrl">
          <img
            :src="thumbnailUrl"
            alt="Model thumbnail"
            class="thumbnail-image"
          />
        </div>

        <!-- 操作提示 -->
        <div class="controls-hint">
          <span>Left: Rotate</span>
          <span>Wheel: Zoom</span>
          <span>Right: Pan</span>
        </div>

        <!-- 光照控制面板 -->
        <div class="lighting-controls-wrapper">
          <!-- 触发按钮 -->
          <button
            class="lighting-toggle"
            @click.stop="showLightingControls = !showLightingControls"
            :class="{ active: showLightingControls }"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
            >
              <circle cx="12" cy="12" r="5" />
              <path
                d="M12 1v2M12 21v2M4.22 4.22l1.42 1.42M18.36 18.36l1.42 1.42M1 12h2M21 12h2M4.22 19.78l1.42-1.42M18.36 5.64l1.42-1.42"
              />
            </svg>
          </button>

          <!-- 控制面板 -->
          <div
            class="lighting-controls"
            v-show="showLightingControls"
            @click="handleLightingControlsClick"
          >
            <div class="control-group">
              <label>Direction</label>
              <div class="direction-control">
                <div
                  class="direction-circle"
                  @mousedown="startDirectionDrag"
                  @mousemove="handleDirectionDrag"
                  @mouseup="stopDirectionDrag"
                  @mouseleave="stopDirectionDrag"
                >
                  <div
                    class="direction-pointer"
                    :style="{
                      left: `${50 + lightingSettings.direction.x}%`,
                      top: `${50 + lightingSettings.direction.y}%`,
                    }"
                  ></div>
                </div>
              </div>
            </div>

            <div class="control-group">
              <label>Depth</label>
              <input
                type="range"
                v-model="lightingSettings.depth"
                min="0"
                max="10"
                step="0.1"
              />
            </div>

            <div class="control-group">
              <label>Highlights</label>
              <div class="highlights-controls">
                <div class="highlight-control">
                  <label>Power</label>
                  <input
                    type="range"
                    v-model="lightingSettings.highlights.power"
                    min="0"
                    max="100"
                  />
                </div>
                <div class="highlight-control">
                  <label>Intensity</label>
                  <input
                    type="range"
                    v-model="lightingSettings.highlights.intensity"
                    min="0"
                    max="100"
                  />
                </div>
              </div>
            </div>

            <div class="control-group">
              <label>Ambient Light</label>
              <div class="ambient-control">
                <label>Intensity</label>
                <input
                  type="range"
                  v-model="lightingSettings.ambient.intensity"
                  min="0"
                  max="100"
                  step="1"
                />
                <div class="value-display">
                  {{ lightingSettings.ambient.intensity }}%
                </div>
              </div>
            </div>

            <div class="control-group">
              <label>Background</label>
              <div class="background-options">
                <button
                  class="bg-option"
                  :class="{
                    active: lightingSettings.background === 'transparent',
                  }"
                  @click="lightingSettings.background = 'transparent'"
                >
                  Transparent
                </button>
                <button
                  class="bg-option color-bg-option"
                  :class="{ active: lightingSettings.background === 'color' }"
                  @click="handleColorButtonClick"
                >
                  Color
                  <div
                    v-if="lightingSettings.background === 'color'"
                    class="color-preview"
                    :style="{
                      backgroundColor: lightingSettings.backgroundColor,
                    }"
                  ></div>
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- 添加颜色选择器，根据 showColorPicker 显示/隐藏 -->
        <div
          class="color-picker"
          v-if="lightingSettings.background === 'color' && showColorPicker"
        >
          <hex-color-picker
            :color="lightingSettings.backgroundColor"
            @color-changed="handleColorChange"
            class="custom-color-picker"
          ></hex-color-picker>
          <span class="color-value">{{
            lightingSettings.backgroundColor
          }}</span>
        </div>

        <!-- 在 display-mode-controls 之前添加显示模式控制 -->
        <div
          class="display-mode-controls"
          @click.stop
          @click="toggleMaterialsPopup"
        >
          <MaterialIcon
            :icon="
              displayModes.find((m) => m.value === currentDisplayMode)?.icon ||
              displayModes[0].icon
            "
            :name="
              displayModes.find((m) => m.value === currentDisplayMode)?.name ||
              displayModes[0].name
            "
            :isActive="true"
          />

          <!-- 材质选择弹窗 -->
          <div class="materials-popup" v-if="showMaterialsPopup">
            <MaterialIcon
              v-for="mode in displayModes"
              :key="mode.value"
              :icon="mode.icon"
              :name="mode.name"
              :isActive="currentDisplayMode === mode.value"
              @click="changeDisplayMode(mode.value)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  ref,
  onUnmounted,
  watch,
  nextTick,
} from "vue";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { VertexNormalsHelper } from "three/examples/jsm/helpers/VertexNormalsHelper";
import { materialsIcons } from "@/assets/icons/materialIcons";
import "vanilla-colorful";
import LogoItem from "./LogoItem.vue";
import MaterialIcon from "./MaterialIcon.vue";

const actionButtons = [
  {
    name: "copy",
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none">
            <g stroke="currentColor" stroke-linejoin="round" clip-path="url(#CloseAll_svg__a)">
              <path d="M4.445 4.504V2.508a1.5 1.5 0 0 1 1.5-1.5h6.55a1.5 1.5 0 0 1 1.5 1.5v6.55a1.5 1.5 0 0 1-1.5 1.5h-1.957"></path>
              <rect width="9.585" height="9.585" x="0.91" y="4.505" rx="1.5"></rect>
            </g>
          </svg>`,
    tooltip: "Copy to clipboard",
  },
  {
    name: "share",
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none" class="text-white">
      <g stroke="currentColor" stroke-linecap="round" clip-path="url(#SaveAs_svg__a)">
        <path d="M9.577 11.715h2.076a2.796 2.796 0 1 0-.455-5.555l.001-.082a3.699 3.699 0 1 0-7.397.082 2.796 2.796 0 1 0-.455 5.555h2.076"></path>
        <path stroke-linejoin="round" d="M7.5 12.533v-5.98M9.973 9.03 7.495 6.553 5.018 9.03"></path>
      </g><defs><clipPath id="SaveAs_svg__a"><path fill="#fff" d="M0 0h15v15H0z"></path></clipPath></defs>
    </svg>`,
    tooltip: "Share",
  },
  {
    name: "download",
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" 
              d="M7.5 1.488v9.99M2.488 6.467l5.014 5.012 5.012-5.012M13.48 13.512H1.516">
            </path>
          </svg>`,
    tooltip: "Download",
  },
  {
    name: "close",
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" 
              d="M3.64 3.64l7.72 7.72M11.36 3.64l-7.72 7.72">
            </path>
          </svg>`,
    tooltip: "Close",
  },
];

// 修改 displayModes 常量定义
const displayModes = [
  { name: "Texture", value: "texture", icon: materialsIcons.texture },
  { name: "Wireframe", value: "wireframe", icon: materialsIcons.wireframe },
  { name: "Vertices", value: "vertices", icon: materialsIcons.vertices },
  { name: "Untextured", value: "untextured", icon: materialsIcons.mesh },
  { name: "Normals", value: "normals", icon: materialsIcons.normal },
];

// 在 script 部分添加新的响应式变量
const isMaterialsExpanded = ref(false);

// 修改展开/关闭按钮的图标，改为左右方向
const expandIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9 18L15 12L9 6" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

const collapseIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M15 6L9 12L15 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

export default defineComponent({
  name: "ModelViewer",
  components: {
    LogoItem,
    MaterialIcon,
  },
  props: {
    modelData: {
      type: String,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    thumbnailUrl: {
      type: String,
      default: "",
    },
    isBaseModel: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close", "download"],
  setup(props, { emit }) {
    const canvas = ref<HTMLCanvasElement | null>(null);

    let scene: THREE.Scene | null = null;
    let camera: THREE.PerspectiveCamera | null = null;
    let renderer: THREE.WebGLRenderer | null = null;
    let controls: OrbitControls | null = null;
    let model: THREE.Object3D | null = null;
    let lights: {
      ambient: THREE.AmbientLight | null;
      directional: THREE.DirectionalLight | null;
    } = {
      ambient: null,
      directional: null,
    };
    let animationFrameId: number;

    const showLightingControls = ref(false);
    const lightingSettings = ref({
      direction: { x: 35, y: -35 },
      depth: 5,
      background: "color",
      backgroundColor: "#a5adde",
      highlights: {
        power: 75,
        intensity: 100,
      },
      ambient: {
        intensity: 50,
      },
    });

    const currentDisplayMode = ref(displayModes[0].value);
    let normalHelper: VertexNormalsHelper | null = null;

    const changeDisplayMode = (mode: string) => {
      if (!model || !scene) return;

      currentDisplayMode.value = mode;
      showMaterialsPopup.value = false;

      // 清除之前的法辅助对象
      if (normalHelper) {
        scene.remove(normalHelper);
        normalHelper = null;
      }

      // 预先声明材质变量

      model.traverse((object: THREE.Object3D) => {
        if (object instanceof THREE.Mesh) {
          // 保存原始材
          if (!object.userData.originalMaterial) {
            object.userData.originalMaterial = object.material;
          }

          switch (mode) {
            case "texture":
              object.material = object.userData.originalMaterial;
              break;

            case "wireframe":
              object.material = new THREE.MeshBasicMaterial({
                wireframe: true,
                color: 0xffffff,
              });
              break;

            case "vertices":
              object.material = new THREE.PointsMaterial({
                size: 2,
                color: 0xffffff,
              });
              break;

            case "untextured":
              object.material = new THREE.MeshStandardMaterial({
                color: 0xcccccc,
                roughness: 0.7,
                metalness: 0.1,
              });
              break;

            case "normals":
              // 创建法线辅助对象
              object.material = new THREE.MeshNormalMaterial({
                flatShading: false, // 使用平滑着色
              });
              break;
          }
        }
      });
    };

    // 方向控制相关状态
    const isDragging = ref(false);
    const startDirectionDrag = (event: MouseEvent) => {
      isDragging.value = true;
      handleDirectionDrag(event);
    };

    const handleDirectionDrag = (event: MouseEvent) => {
      if (!isDragging.value) return;

      const circle = event.currentTarget as HTMLElement;
      const rect = circle.getBoundingClientRect();

      // 计算对位置 (-50 到 50)
      const x = ((event.clientX - rect.left) / rect.width) * 100 - 50;
      const y = ((event.clientY - rect.top) / rect.height) * 100 - 50;

      // 限制在圆形范围内
      const distance = Math.sqrt(x * x + y * y);
      const maxDistance = 50;

      if (distance > maxDistance) {
        const scale = maxDistance / distance;
        lightingSettings.value.direction.x = x * scale;
        lightingSettings.value.direction.y = y * scale;
      } else {
        lightingSettings.value.direction.x = x;
        lightingSettings.value.direction.y = y;
      }

      updateLighting();
    };

    const stopDirectionDrag = () => {
      isDragging.value = false;
    };

    // 更新光照设置
    const updateLighting = () => {
      if (!lights.directional || !scene) return;

      // 更新向光位置
      const { x, y } = lightingSettings.value.direction;
      const depth = lightingSettings.value.depth;
      lights.directional.position.set((x / 35) * 5, -(y / 35) * 5, depth);

      // 更新光照度
      const { power, intensity } = lightingSettings.value.highlights;
      const ambientIntensity = lightingSettings.value.ambient.intensity;

      if (lights.ambient) {
        lights.ambient.intensity = ambientIntensity / 50; // 将 0-100 映射到 0-2
      }

      // 同时使用 power 和 intensity 来控制方向光
      lights.directional.intensity = (power * intensity) / 5000; // 将 (0-100) * (0-100) 映射到合适的范围

      // 更新背景
      if (scene) {
        if (lightingSettings.value.background === "transparent") {
          scene.background = null;
          if (renderer) {
            renderer.setClearColor(0x000000, 0);
          }
        } else {
          scene.background = new THREE.Color(
            lightingSettings.value.backgroundColor
          );
        }
      }
    };

    // 监听光照设置变化
    watch(
      () => lightingSettings.value,
      () => {
        updateLighting();
      },
      { deep: true }
    );

    const initScene = () => {
      try {
        // 更严格的清理逻
        if (scene) {
          scene.traverse((object: THREE.Object3D) => {
            if (object instanceof THREE.Mesh) {
              object.geometry?.dispose();
              if (object.material instanceof THREE.Material) {
                object.material.dispose();
              } else if (Array.isArray(object.material)) {
                object.material.forEach((material: THREE.Material) =>
                  material.dispose()
                );
              }
            }
          });
          scene.clear();
          renderer?.dispose();
          controls?.dispose();
          scene = null;
        }

        scene = new THREE.Scene();
        scene.background = new THREE.Color(
          lightingSettings.value.backgroundColor
        );

        const container = canvas.value?.parentElement;
        const width = container?.clientWidth || window.innerWidth;
        const height = container?.clientHeight || window.innerHeight;

        camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
        camera.position.z = 5;

        renderer = new THREE.WebGLRenderer({
          canvas: canvas.value as HTMLCanvasElement,
          antialias: true,
          alpha: true,
        });
        renderer.setSize(width, height);
        renderer.setPixelRatio(window.devicePixelRatio);

        controls = new OrbitControls(camera, renderer.domElement);
        controls.enableDamping = true;
        controls.dampingFactor = 0.05;
        controls.screenSpacePanning = true;

        // Add lights with values matching the default settings
        lights = {
          ambient: new THREE.AmbientLight(
            0xffffff,
            lightingSettings.value.ambient.intensity / 50
          ),
          directional: new THREE.DirectionalLight(
            0xffffff,
            lightingSettings.value.highlights.power / 50
          ),
        };

        if (lights.directional && scene) {
          lights.directional.position.set(
            (lightingSettings.value.direction.x / 35) * 5,
            -(lightingSettings.value.direction.y / 35) * 5,
            lightingSettings.value.depth
          );
          if (lights.ambient) scene.add(lights.ambient);
          scene.add(lights.directional);
        }

        // 设置初始背景
        if (scene) {
          if (lightingSettings.value.background === "transparent") {
            scene.background = null;
            if (renderer) {
              renderer.setClearColor(0x000000, 0);
            }
          } else {
            scene.background = new THREE.Color(
              lightingSettings.value.backgroundColor
            );
          }
        }

        loadModel();
        animate();
      } catch (e) {
        console.error("Scene initialization error:", e);
        throw e;
      }
    };

    const newModel = ref<THREE.Object3D | null>(null);

    const loadModel = () => {
      try {
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath("/draco/");

        const loader = new GLTFLoader();
        loader.setDRACOLoader(dracoLoader);

        // 将 base64 转换为 Blob
        const modelBlob = base64ToBlob(props.modelData);
        const modelUrl = URL.createObjectURL(modelBlob);

        loader.load(
          modelUrl,
          (gltf) => {
            if (!scene || !camera || !controls) return;

            console.log("Model loaded successfully:", gltf);
            const loadedModel = gltf.scene;

            // 保存原始材质
            loadedModel.traverse((object: THREE.Object3D) => {
              if (object instanceof THREE.Mesh) {
                object.userData.originalMaterial = object.material;
              }
            });

            // 如果是替换现有模型，先保存新模型
            if (model) {
              newModel.value = loadedModel;
              // 新模型加载完成后，移除旧模型并显示新模型
              if (scene) {
                scene.remove(model);
                scene.add(loadedModel);
                model = loadedModel;
                newModel.value = null;
              }
            } else {
              // 首次加载模型
              scene.add(loadedModel);
              model = loadedModel;
            }

            const box = new THREE.Box3().setFromObject(loadedModel);
            const center = box.getCenter(new THREE.Vector3());
            const size = box.getSize(new THREE.Vector3());

            loadedModel.position.x = -center.x;
            loadedModel.position.y = -center.y;
            loadedModel.position.z = -center.z;

            const maxDim = Math.max(size.x, size.y, size.z);
            camera.position.z = maxDim * 2;

            controls.target.set(0, 0, 0);
            controls.update();

            // 清理 URL
            URL.revokeObjectURL(modelUrl);
          },
          undefined,
          (err: unknown) => {
            console.error("Model loading error:", err);
            URL.revokeObjectURL(modelUrl);
            throw err;
          }
        );
      } catch (error) {
        console.error("Error in loadModel:", error);
        throw error;
      }
    };

    const handleClose = () => {
      if (normalHelper && scene) {
        scene.remove(normalHelper);
        normalHelper = null;
      }
      // 清理场景
      if (scene) {
        scene.clear();
        renderer?.dispose();
        controls?.dispose();
        scene = null; // 置场景
        model = null; // 重置模型
      }
      emit("close");
    };

    const handleDownload = () => {
      emit("download", props.modelData);
    };

    const handleResize = () => {
      if (!canvas.value || !renderer || !camera) return;

      const container = canvas.value.parentElement;
      const width = container?.clientWidth || window.innerWidth;
      const height = container?.clientHeight || window.innerHeight;

      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      renderer.setSize(width, height);
    };

    const animate = () => {
      animationFrameId = requestAnimationFrame(animate);
      if (controls && scene && camera && renderer) {
        controls.update();
        if (normalHelper) {
          normalHelper.update();
        }
        renderer.render(scene, camera);
      }
    };

    const handleAction = (action: string) => {
      switch (action) {
        case "copy":
          // 实现复制链接功能
          navigator.clipboard.writeText(window.location.href);
          break;
        case "share":
          // 现分享功能
          if (navigator.share) {
            navigator.share({
              title: "Share Model",
              url: window.location.href,
            });
          }
          break;
        case "download":
          handleDownload();
          break;
        case "close":
          handleClose();
          break;
      }
    };

    const handleColorChange = (event: CustomEvent) => {
      console.log("Color changed:", event);
      lightingSettings.value.backgroundColor = event.detail.value;
    };

    watch(
      [() => props.visible, () => props.modelData],
      ([newVisible, newModelData]) => {
        nextTick(() => {
          console.log("Watch triggered:", {
            newVisible,
            newModelData,
            canvas: canvas.value,
          });
          if (newVisible && canvas.value) {
            console.log("Initializing scene...");
            initScene();
          }
        });
      },
      { immediate: true }
    );

    watch(
      () => props.modelData,
      (newData) => {
        if (newData && scene) {
          // 直接加载新模型，不移除旧模型
          loadModel();
        }
      }
    );

    onMounted(() => {
      console.log("Component mounted");

      // 给canvas一点时间来初始化
      nextTick(() => {
        console.log("nextTick - canvas value:", canvas.value);
        if (canvas.value) {
          if (props.visible) {
            console.log("Initializing scene in nextTick");
            initScene();
          } else {
            console.log("Component not visible yet");
          }
        } else {
          console.warn("Canvas not available after nextTick");
        }
      });

      window.addEventListener("resize", handleResize);
    });

    onUnmounted(() => {
      console.log("onUnmounted");
      window.removeEventListener("resize", handleResize);
      cancelAnimationFrame(animationFrameId);
      controls?.dispose();
      renderer?.dispose();
      isDragging.value = false;
    });

    // 在 setup 函数中添加事件处理函数
    const handleWheel = (event: WheelEvent) => {
      event.stopPropagation();
    };

    // 添加 base64 转换函数
    const base64ToBlob = (base64: string) => {
      const binaryString = window.atob(base64);
      const bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      return new Blob([bytes], { type: "model/gltf-binary" });
    };

    const showMaterialsPopup = ref(false);

    const handleClickOutside = (event: MouseEvent) => {
      if (
        !event.target ||
        !(event.target as HTMLElement).closest(".display-mode-controls")
      ) {
        showMaterialsPopup.value = false;
      }

      // 点击外部时关闭颜色选择器
      if (
        !(event.target as HTMLElement).closest(".color-bg-option") &&
        !(event.target as HTMLElement).closest(".color-picker")
      ) {
        showColorPicker.value = false;
      }

      // 检查点击是否在光照控制面板外
      if (
        !(event.target as HTMLElement).closest(".lighting-controls-wrapper") &&
        !(event.target as HTMLElement).closest(".lighting-toggle")
      ) {
        showLightingControls.value = false;
      }
    };

    const toggleMaterialsPopup = (event: Event) => {
      // 阻止事件冒泡
      event.stopPropagation();
      showMaterialsPopup.value = !showMaterialsPopup.value;
    };

    onMounted(() => {
      document.addEventListener("click", handleClickOutside);
    });

    onUnmounted(() => {
      document.removeEventListener("click", handleClickOutside);
    });

    const showColorPicker = ref(false);

    // 添加 handleColorButtonClick 函数
    const handleColorButtonClick = () => {
      console.log("handleColorButtonClick");
      if (lightingSettings.value.background === "color") {
        console.log("showColorPicker.value:", showColorPicker.value);
        showColorPicker.value = !showColorPicker.value;
      } else {
        lightingSettings.value.background = "color";
        showColorPicker.value = true;
      }
    };

    // 在 setup 函数中添加处理函数
    const handleLightingControlsClick = (event: MouseEvent) => {
      // 阻止事件冒泡，这样点击控制面板内部时不会触发关闭
      event.stopPropagation();
    };

    return {
      canvas,
      handleClose,
      handleDownload,
      actionButtons,
      handleAction,
      showLightingControls,
      lightingSettings,
      startDirectionDrag,
      handleDirectionDrag,
      stopDirectionDrag,
      handleColorChange,
      displayModes,
      currentDisplayMode,
      changeDisplayMode,
      handleWheel,
      isMaterialsExpanded,
      expandIcon,
      collapseIcon,
      showMaterialsPopup,
      toggleMaterialsPopup,
      showColorPicker,
      handleColorButtonClick,
      handleLightingControlsClick,
    };
  },
});
</script>

<style scoped lang="scss">
.model-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  .modal-content {
    position: relative;
    width: 80vw;
    height: 80vh;
    background: #1a1a1a;
    border-radius: 20px;
    overflow: hidden;

    .logo-container {
      position: absolute;
      top: 20px;
      left: 20px;
      z-index: 10;
      height: 32px; // 调整 logo 大小
    }
  }

  .action-buttons {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    gap: 10px;
    z-index: 10;

    .action-btn {
      width: 35px;
      height: 35px;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.1);
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        background: rgba(255, 255, 255, 0.2);
        transform: translateY(-2px);
      }

      // 为关闭按钮添加特殊样式
      &[title="Close"] {
        margin-left: 15px; // 增加与其他按钮的间距
        background: rgba(255, 255, 255, 0.15); // 稍微不同的背景色

        &:hover {
          background: rgba(255, 255, 255, 0.25);
        }
      }

      svg {
        width: 15px;
        height: 15px;
      }
    }
  }
}

.model-viewer {
  width: 100%;
  height: 100%;
  position: relative;

  .model-canvas {
    width: 100%;
    height: 100%;
  }

  .controls-hint {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 20px;
    background: rgba(0, 0, 0, 0.5);
    padding: 8px 16px;
    border-radius: 8px;
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;

    span {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  .lighting-controls-wrapper {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 10;

    .lighting-toggle {
      width: 40px;
      height: 40px;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.1);
      border: none;
      color: white;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease;

      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }

      &.active {
        background: rgba(255, 255, 255, 0.3);
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .lighting-controls {
      position: absolute;
      bottom: 50px;
      right: 0;
      width: 280px;
      background: rgba(0, 0, 0, 0.8);
      border-radius: 12px;
      padding: 16px;
      color: white;

      .control-group {
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }

        > label {
          display: block;
          margin-bottom: 8px;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.7);
        }
      }

      .direction-control {
        .direction-circle {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          border: 2px solid rgba(255, 255, 255, 0.3);
          position: relative;
          margin: 0 auto;

          .direction-pointer {
            width: 12px;
            height: 12px;
            background: white;
            border-radius: 50%;
            position: absolute;
            transform: translate(-50%, -50%);
            cursor: pointer;
          }
        }
      }

      input[type="range"] {
        width: 100%;
        height: 4px;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 2px;
        -webkit-appearance: none;

        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          width: 16px;
          height: 16px;
          background: white;
          border-radius: 50%;
          cursor: pointer;
        }
      }

      .background-options {
        display: flex;
        gap: 8px;

        .bg-option {
          flex: 1;
          padding: 8px 12px;
          border-radius: 6px;
          border: 1px solid rgba(255, 255, 255, 0.2);
          background: rgba(255, 255, 255, 0.05);
          color: rgba(255, 255, 255, 0.8);
          font-size: 13px;
          cursor: pointer;
          transition: all 0.2s ease;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;

          &:hover {
            background: rgba(255, 255, 255, 0.1);
            transform: translateY(-1px);
          }

          &.active {
            background: rgba(255, 255, 255, 0.15);
            border-color: rgba(255, 255, 255, 0.4);
            color: white;
          }

          &.color-bg-option {
            position: relative;
            padding-right: 32px; // 为颜色预览块留出空间
          }
        }

        .color-preview {
          position: absolute;
          right: 8px;
          width: 16px;
          height: 16px;
          border-radius: 3px;
          border: 1px solid rgba(255, 255, 255, 0.3);
          pointer-events: none; // 禁用点击事件
        }
      }

      .highlights-controls {
        .highlight-control {
          margin-bottom: 12px;

          &:last-child {
            margin-bottom: 0;
          }

          label {
            display: block;
            margin-bottom: 4px;
            font-size: 12px;
            color: rgba(255, 255, 255, 0.6);
          }
        }
      }

      .ambient-control {
        margin-top: 8px;

        label {
          display: block;
          font-size: 12px;
          color: rgba(255, 255, 255, 0.6);
          margin-bottom: 4px;
        }

        input[type="range"] {
          width: 100%;
          margin-bottom: 4px;
        }

        .value-display {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.6);
          text-align: right;
        }
      }
    }
  }

  .thumbnail-container {
    position: absolute;
    bottom: 100px; // 调高位置，避免与按钮重叠
    left: 20px;
    z-index: 10;
    width: 120px;
    height: 120px;
    border-radius: 12px;
    overflow: hidden;
    border: 2px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.05);
      border-color: rgba(255, 255, 255, 0.2);
    }

    .thumbnail-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.color-picker {
  position: absolute;
  bottom: 80px;
  right: 300px;
  display: flex;
  gap: 10px;
  z-index: 10;
  width: 240px;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  z-index: 100;

  .custom-color-picker {
    width: 100% !important;
    height: 150px !important;

    // 自定义颜色选择器样式
    --pcr-color: var(--color);
    border-radius: 8px;
    overflow: hidden;

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
    }
  }

  .color-value {
    font-size: 13px;
    font-family: "Monaco", "Menlo", monospace;
    color: rgba(255, 255, 255, 0.8);
    padding: 6px 10px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    text-transform: uppercase;
    text-align: center;
  }
}

.background-options {
  .color-option-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .bg-option {
    flex: 1;
    padding: 8px 12px;
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: rgba(255, 255, 255, 0.05);
    color: rgba(255, 255, 255, 0.8);
    font-size: 13px;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background: rgba(255, 255, 255, 0.1);
      transform: translateY(-1px);
    }

    &.active {
      background: rgba(255, 255, 255, 0.15);
      border-color: rgba(255, 255, 255, 0.4);
      color: white;
    }
  }

  .color-preview {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      transform: scale(1.1);
      border-color: rgba(255, 255, 255, 0.4);
    }
  }
}

.display-mode-controls {
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: flex;
  gap: 10px;
  z-index: 10;
  align-items: flex-start;

  .mode-btn {
    position: relative;
    width: 40px;
    height: 40px;
    padding: 8px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.4);
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background: rgba(0, 0, 0, 0.6);
      transform: translateY(-2px);
      border-color: rgba(255, 255, 255, 0.2);

      .mode-name {
        opacity: 1;
        transform: translateY(0);
      }
    }

    &.active {
      background: rgba(0, 0, 0, 0.7);
      border-color: rgba(255, 255, 255, 0.3);
    }

    .mode-icon {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .mode-name {
      position: absolute;
      bottom: calc(100% + 8px);
      left: 50%;
      transform: translateX(-50%);
      background: rgba(0, 0, 0, 0.8);
      padding: 4px 8px;
      border-radius: 4px;
      font-size: 12px;
      white-space: nowrap;
      opacity: 0;
      transition: all 0.2s ease;
      pointer-events: none;
    }
  }
}

.materials-popup {
  position: absolute;
  left: calc(100% + 12px);
  bottom: -12px;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 12px;
  padding: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: row;
  gap: 12px;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  white-space: nowrap;
}

.materials-popup .material-option {
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
    transform: translateY(-2px);

    .material-name {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &.active {
    border-color: rgba(255, 255, 255, 0.5);
    background: rgba(255, 255, 255, 0.15);
  }

  .material-name {
    position: absolute;
    bottom: calc(100% + 8px);
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.8);
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    opacity: 0;
    transition: all 0.2s ease;
    pointer-events: none;
  }
}

// 添加过渡动画
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateX(-20px);
}
</style>
