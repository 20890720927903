import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, vModelText as _vModelText, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-content" }
const _hoisted_2 = { class: "logo-container" }
const _hoisted_3 = { class: "action-buttons" }
const _hoisted_4 = ["title", "onClick", "innerHTML"]
const _hoisted_5 = {
  ref: "canvas",
  class: "model-canvas"
}
const _hoisted_6 = {
  key: 0,
  class: "thumbnail-container"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "lighting-controls-wrapper" }
const _hoisted_9 = { class: "control-group" }
const _hoisted_10 = { class: "direction-control" }
const _hoisted_11 = { class: "control-group" }
const _hoisted_12 = { class: "control-group" }
const _hoisted_13 = { class: "highlights-controls" }
const _hoisted_14 = { class: "highlight-control" }
const _hoisted_15 = { class: "highlight-control" }
const _hoisted_16 = { class: "control-group" }
const _hoisted_17 = { class: "ambient-control" }
const _hoisted_18 = { class: "value-display" }
const _hoisted_19 = { class: "control-group" }
const _hoisted_20 = { class: "background-options" }
const _hoisted_21 = {
  key: 1,
  class: "color-picker"
}
const _hoisted_22 = { class: "color-value" }
const _hoisted_23 = {
  key: 0,
  class: "materials-popup"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LogoItem = _resolveComponent("LogoItem")!
  const _component_hex_color_picker = _resolveComponent("hex-color-picker")!
  const _component_MaterialIcon = _resolveComponent("MaterialIcon")!

  return (_ctx.visible)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "model-modal",
        onClick: _cache[15] || (_cache[15] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleClose && _ctx.handleClose(...args)), ["self"]))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_LogoItem, {
              logoOnly: true,
              brightness: 90
            })
          ]),
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actionButtons, (btn) => {
              return (_openBlock(), _createElementBlock("div", {
                key: btn.name,
                class: "action-btn",
                title: btn.tooltip,
                onClick: ($event: any) => (_ctx.handleAction(btn.name)),
                innerHTML: btn.icon
              }, null, 8, _hoisted_4))
            }), 128))
          ]),
          _createElementVNode("div", {
            class: "model-viewer",
            onWheel: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.handleWheel && _ctx.handleWheel(...args)))
          }, [
            _withDirectives(_createElementVNode("canvas", _hoisted_5, null, 512), [
              [_vShow, _ctx.visible]
            ]),
            (_ctx.thumbnailUrl)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("img", {
                    src: _ctx.thumbnailUrl,
                    alt: "Model thumbnail",
                    class: "thumbnail-image"
                  }, null, 8, _hoisted_7)
                ]))
              : _createCommentVNode("", true),
            _cache[26] || (_cache[26] = _createElementVNode("div", { class: "controls-hint" }, [
              _createElementVNode("span", null, "Left: Rotate"),
              _createElementVNode("span", null, "Wheel: Zoom"),
              _createElementVNode("span", null, "Right: Pan")
            ], -1)),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("button", {
                class: _normalizeClass(["lighting-toggle", { active: _ctx.showLightingControls }]),
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.showLightingControls = !_ctx.showLightingControls), ["stop"]))
              }, _cache[16] || (_cache[16] = [
                _createElementVNode("svg", {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "24",
                  height: "24",
                  viewBox: "0 0 24 24",
                  fill: "none",
                  stroke: "currentColor"
                }, [
                  _createElementVNode("circle", {
                    cx: "12",
                    cy: "12",
                    r: "5"
                  }),
                  _createElementVNode("path", { d: "M12 1v2M12 21v2M4.22 4.22l1.42 1.42M18.36 18.36l1.42 1.42M1 12h2M21 12h2M4.22 19.78l1.42-1.42M18.36 5.64l1.42-1.42" })
                ], -1)
              ]), 2),
              _withDirectives(_createElementVNode("div", {
                class: "lighting-controls",
                onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.handleLightingControlsClick && _ctx.handleLightingControlsClick(...args)))
              }, [
                _createElementVNode("div", _hoisted_9, [
                  _cache[17] || (_cache[17] = _createElementVNode("label", null, "Direction", -1)),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", {
                      class: "direction-circle",
                      onMousedown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.startDirectionDrag && _ctx.startDirectionDrag(...args))),
                      onMousemove: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleDirectionDrag && _ctx.handleDirectionDrag(...args))),
                      onMouseup: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.stopDirectionDrag && _ctx.stopDirectionDrag(...args))),
                      onMouseleave: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.stopDirectionDrag && _ctx.stopDirectionDrag(...args)))
                    }, [
                      _createElementVNode("div", {
                        class: "direction-pointer",
                        style: _normalizeStyle({
                      left: `${50 + _ctx.lightingSettings.direction.x}%`,
                      top: `${50 + _ctx.lightingSettings.direction.y}%`,
                    })
                      }, null, 4)
                    ], 32)
                  ])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _cache[18] || (_cache[18] = _createElementVNode("label", null, "Depth", -1)),
                  _withDirectives(_createElementVNode("input", {
                    type: "range",
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.lightingSettings.depth) = $event)),
                    min: "0",
                    max: "10",
                    step: "0.1"
                  }, null, 512), [
                    [_vModelText, _ctx.lightingSettings.depth]
                  ])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _cache[21] || (_cache[21] = _createElementVNode("label", null, "Highlights", -1)),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _cache[19] || (_cache[19] = _createElementVNode("label", null, "Power", -1)),
                      _withDirectives(_createElementVNode("input", {
                        type: "range",
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.lightingSettings.highlights.power) = $event)),
                        min: "0",
                        max: "100"
                      }, null, 512), [
                        [_vModelText, _ctx.lightingSettings.highlights.power]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_15, [
                      _cache[20] || (_cache[20] = _createElementVNode("label", null, "Intensity", -1)),
                      _withDirectives(_createElementVNode("input", {
                        type: "range",
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.lightingSettings.highlights.intensity) = $event)),
                        min: "0",
                        max: "100"
                      }, null, 512), [
                        [_vModelText, _ctx.lightingSettings.highlights.intensity]
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _cache[23] || (_cache[23] = _createElementVNode("label", null, "Ambient Light", -1)),
                  _createElementVNode("div", _hoisted_17, [
                    _cache[22] || (_cache[22] = _createElementVNode("label", null, "Intensity", -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "range",
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.lightingSettings.ambient.intensity) = $event)),
                      min: "0",
                      max: "100",
                      step: "1"
                    }, null, 512), [
                      [_vModelText, _ctx.lightingSettings.ambient.intensity]
                    ]),
                    _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.lightingSettings.ambient.intensity) + "% ", 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _cache[25] || (_cache[25] = _createElementVNode("label", null, "Background", -1)),
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("button", {
                      class: _normalizeClass(["bg-option", {
                    active: _ctx.lightingSettings.background === 'transparent',
                  }]),
                      onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.lightingSettings.background = 'transparent'))
                    }, " Transparent ", 2),
                    _createElementVNode("button", {
                      class: _normalizeClass(["bg-option color-bg-option", { active: _ctx.lightingSettings.background === 'color' }]),
                      onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.handleColorButtonClick && _ctx.handleColorButtonClick(...args)))
                    }, [
                      _cache[24] || (_cache[24] = _createTextVNode(" Color ")),
                      (_ctx.lightingSettings.background === 'color')
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: "color-preview",
                            style: _normalizeStyle({
                      backgroundColor: _ctx.lightingSettings.backgroundColor,
                    })
                          }, null, 4))
                        : _createCommentVNode("", true)
                    ], 2)
                  ])
                ])
              ], 512), [
                [_vShow, _ctx.showLightingControls]
              ])
            ]),
            (_ctx.lightingSettings.background === 'color' && _ctx.showColorPicker)
              ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                  _createVNode(_component_hex_color_picker, {
                    color: _ctx.lightingSettings.backgroundColor,
                    onColorChanged: _ctx.handleColorChange,
                    class: "custom-color-picker"
                  }, null, 8, ["color", "onColorChanged"]),
                  _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.lightingSettings.backgroundColor), 1)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: "display-mode-controls",
              onClick: [
                _cache[12] || (_cache[12] = _withModifiers(() => {}, ["stop"])),
                _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.toggleMaterialsPopup && _ctx.toggleMaterialsPopup(...args)))
              ]
            }, [
              _createVNode(_component_MaterialIcon, {
                icon: 
              _ctx.displayModes.find((m) => m.value === _ctx.currentDisplayMode)?.icon ||
              _ctx.displayModes[0].icon
            ,
                name: 
              _ctx.displayModes.find((m) => m.value === _ctx.currentDisplayMode)?.name ||
              _ctx.displayModes[0].name
            ,
                isActive: true
              }, null, 8, ["icon", "name"]),
              (_ctx.showMaterialsPopup)
                ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayModes, (mode) => {
                      return (_openBlock(), _createBlock(_component_MaterialIcon, {
                        key: mode.value,
                        icon: mode.icon,
                        name: mode.name,
                        isActive: _ctx.currentDisplayMode === mode.value,
                        onClick: ($event: any) => (_ctx.changeDisplayMode(mode.value))
                      }, null, 8, ["icon", "name", "isActive", "onClick"]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ])
          ], 32)
        ])
      ]))
    : _createCommentVNode("", true)
}