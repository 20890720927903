<template>
  <div
    class="material-icon"
    :class="{ active: isActive }"
    @click="$emit('click')"
  >
    <div class="icon-wrapper" v-html="icon"></div>
    <div class="tooltip" :class="{ visible: showTooltip }">
      {{ name }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "MaterialIcon",
  props: {
    icon: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const showTooltip = ref(false);

    return {
      showTooltip,
    };
  },
});
</script>

<style scoped lang="scss">
.material-icon {
  position: relative;
  width: 48px;
  height: 48px;
  padding: 10px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(0, 0, 0, 0.6);
    transform: translateY(-2px);
    border-color: rgba(255, 255, 255, 0.2);

    .tooltip {
      opacity: 1;
      transform: translateX(-50%) translateY(0);
    }
  }

  &.active {
    background: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.3);
  }

  .icon-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    :deep(svg) {
      width: 24px;
      height: 24px;
    }
  }

  .tooltip {
    position: absolute;
    bottom: calc(100% + 8px);
    left: 50%;
    transform: translateX(-50%) translateY(5px);
    background: rgba(0, 0, 0, 0.8);
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    opacity: 0;
    transition: all 0.2s ease;
    pointer-events: none;
    z-index: 100;
  }
}
</style>
