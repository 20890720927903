// 定义图片数据接口
export interface ImageItem {
  id: number;
  url: string;
  snapUrl?: string;
}

// 图片 0.png ~ 85.png
const mockImages: ImageItem[] = Array.from({ length: 82 }, (_, i) => ({
  id: i + 1,
  url: `/mock/images/${i}.png`,
}));

// 视频 1.mp4 ~ 82.mp4
const get_idx = (i: number) => (i + 1 < 12 ? i + 1 : i + 2);
const mockVideos: ImageItem[] = Array.from({ length: 82 }, (_, i) => ({
  id: i + 1,
  url: `/mock/videos/${get_idx(i)}.mp4`,
  snapUrl: `/mock/videoSnapshots/${get_idx(i)}.jpg`,
}));

// 获取所有图片
export const getAllImages = (): Promise<ImageItem[]> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(mockVideos);
    }, 300);
  });
};

// 根据ID获取单个图片
export const getImageById = (id: number): Promise<ImageItem | undefined> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const image = mockImages.find((img) => img.id === id);
      resolve(image);
    }, 300);
  });
};
