<template>
  <div
    class="upload-block"
    :class="{ 'is-dragging': isDragging }"
    @click="triggerFileUpload"
    @dragover="handleDragOver"
    @dragleave="handleDragLeave"
    @drop="handleDrop"
  >
    <input
      type="file"
      ref="fileInput"
      @change="handleFileUpload"
      accept="image/*"
      class="file-input"
    />
    <div
      class="upload-content"
      :class="{ 'has-preview': previewImages.length > 0 }"
    >
      <template v-if="previewImages.length === 0">
        <div class="upload-icon">
          <svg
            width="48"
            height="48"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21 19V5C21 3.9 20.1 3 19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19ZM8.5 13.5L11 16.51L14.5 12L19 18H5L8.5 13.5Z"
              fill="currentColor"
            />
          </svg>
        </div>
        <div class="upload-text">
          <div>Drag & Drop image here</div>
          <div>OR click to upload</div>
        </div>
        <div class="supported-formats">
          Supported Formats: JPG, JPEG, PNG, WEBP. Max Size: 20MB.
        </div>
      </template>
      <template v-else>
        <div class="preview-container" :class="{ loading: isLoading }">
          <div
            v-for="(preview, index) in previewImages"
            :key="index"
            class="preview-image-wrapper"
          >
            <img :src="preview" class="preview-image" />
            <button class="remove-image" @click.stop="removeImage(index)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>
        </div>
      </template>
    </div>
    <Transition name="fade-slide">
      <div class="action-buttons" v-show="previewImages.length > 0">
        <div class="placeholder-btn"></div>
        <button
          class="generate-btn"
          @click="handleGenerateClick"
          :disabled="isLoading"
        >
          Generate
        </button>
        <button
          class="settings-btn"
          @click="handleSettingsClick"
          :disabled="isLoading"
        >
          <i class="fa-solid fa-sliders fa-fw"></i>
        </button>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, onUnmounted } from "vue";

export default defineComponent({
  name: "ImageUploader",
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const previewImages = ref<string[]>([]);
    const fileInput = ref<HTMLInputElement | null>(null);
    const isDragging = ref(false);
    const file = ref<File | null>(null);

    watch(
      previewImages,
      (newImages) => {
        emit("update:hasImage", newImages.length > 0);
      },
      { deep: true }
    );

    const triggerFileUpload = () => {
      fileInput.value?.click();
    };

    const handleFileUpload = (event: Event) => {
      const target = event.target as HTMLInputElement;
      const files = target.files;

      if (files) {
        file.value = files[0];
        Array.from(files).forEach((file) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            if (e.target?.result) {
              previewImages.value.push(e.target.result as string);
            }
          };
          reader.readAsDataURL(file);
        });
      }
      target.value = "";
    };

    const removeImage = (index: number) => {
      previewImages.value.splice(index, 1);
    };

    const handleDragOver = (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();
      isDragging.value = true;
    };

    const handleDragLeave = (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();
      isDragging.value = false;
    };

    const handleDrop = (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();
      isDragging.value = false;

      const files = event.dataTransfer?.files;
      if (files) {
        file.value = files[0];
        Array.from(files).forEach((file) => {
          if (file.type.startsWith("image/")) {
            const reader = new FileReader();
            reader.onload = (e) => {
              if (e.target?.result) {
                previewImages.value.push(e.target.result as string);
              }
            };
            reader.readAsDataURL(file);
          }
        });
      }
    };

    const handleGenerateClick = async (event: Event) => {
      event.stopPropagation();
      if (!file.value) return;
      emit("generate", file.value);
    };

    const handleSettingsClick = (event: Event) => {
      event.stopPropagation();
      emit("settings");
    };

    onUnmounted(() => {
      // No need to clean up polling logic as it's handled by AppView2
    });

    return {
      previewImages,
      fileInput,
      isDragging,
      triggerFileUpload,
      handleFileUpload,
      removeImage,
      handleDragOver,
      handleDragLeave,
      handleDrop,
      handleGenerateClick,
      handleSettingsClick,
    };
  },
});
</script>

<style scoped lang="scss">
.upload-block {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  cursor: pointer;
  position: relative;
  background: transparent;

  &::after {
    content: "";
    position: absolute;
    inset: -6px;
    border-radius: 22px;
    background: rgba(60, 103, 227, 0.15);
    filter: blur(25px);
    opacity: 0.4;
    z-index: -2;
  }

  &::before {
    content: "";
    position: absolute;
    inset: -3px;
    background: linear-gradient(
      var(--rotate, 132deg),
      #5ddcff,
      #3c67e3 43%,
      #4e00c2
    );
    border-radius: 22px;
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    padding: 3px;
    animation: spin 4s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    z-index: -1;
  }

  .upload-content {
    position: relative;
    width: 100%;
    height: 100%;
    background: transparent;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    transition: all 0.3s ease;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 20px;
      opacity: 0.6;
      transition: all 0.3s ease;
    }

    &:hover::before {
      opacity: 1;
    }
  }

  .upload-icon {
    color: white;
    opacity: 0.8;
    transition: all 0.3s ease;
  }

  .upload-text {
    color: white;
    font-size: min(1.2rem, calc(var(--cell-size) * 0.12));
    opacity: 0.8;
    font-weight: 500;
    letter-spacing: 0.5px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, sans-serif;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }

  &.is-dragging {
    .upload-content {
      background: transparent;
      transform: scale(1.02);

      &::before {
        border-color: rgba(74, 144, 226, 0.6);
        border-style: solid;
        opacity: 1;
      }
    }

    .upload-icon {
      transform: scale(1.1);
      opacity: 1;
    }

    .upload-text {
      opacity: 1;
      transform: translateY(4px);
    }
  }
}

@property --rotate {
  syntax: "<angle>";
  initial-value: 132deg;
  inherits: false;
}

@keyframes spin {
  0% {
    --rotate: 0deg;
  }
  100% {
    --rotate: 360deg;
  }
}

.file-input {
  display: none;
}

.preview-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 20px;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  &.loading {
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      --h: 197;
      background: radial-gradient(
        circle at 50% 50%,
        hsla(var(--h), 71%, 73%, 0.8) 0%,
        hsla(var(--h), 71%, 73%, 0.1) 30%,
        transparent 50%
      );
      animation: starShine 2s infinite, rotateHue 10s infinite;
    }

    &::after {
      content: "";
      position: absolute;
      inset: 0;
      backdrop-filter: blur(0px);
      animation: dynamicBlur 3s infinite;
      border-radius: 20px;
    }
  }
}

.preview-image-wrapper {
  position: relative;
  width: 80%;
  max-width: calc(2 * var(--cell-size));
  aspect-ratio: 1;
  border-radius: 12px;
  overflow: hidden;

  &:hover .remove-image {
    opacity: 1;
  }
}

@keyframes starShine {
  0% {
    transform: scale(0.5) rotate(0deg);
    opacity: 0;
  }
  50% {
    transform: scale(1.5) rotate(180deg);
    opacity: 1;
  }
  100% {
    transform: scale(0.5) rotate(360deg);
    opacity: 0;
  }
}

@property --h {
  syntax: "<number>";
  initial-value: 0;
  inherits: false;
}

@keyframes rotateHue {
  0% {
    --h: 197;
  }
  33% {
    --h: 360;
  }
  66% {
    --h: 0;
  }
  100% {
    --h: 197;
  }
}

@keyframes dynamicBlur {
  0% {
    backdrop-filter: blur(0px);
  }
  33% {
    backdrop-filter: blur(4px);
  }
  66% {
    backdrop-filter: blur(8px);
  }
  100% {
    backdrop-filter: blur(0px);
  }
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.remove-image {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 35px;
  height: 35px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.15);
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease;
  padding: 0;

  &:hover {
    background: rgba(255, 255, 255, 0.25);
    transform: translateY(-2px);
  }

  svg {
    width: 15px;
    height: 15px;
  }
}

.preview-image-wrapper:hover .remove-image {
  opacity: 1;
}

.action-buttons {
  position: absolute;
  left: 50%;
  bottom: calc(var(--cell-size) * -0.4);
  transform: translateX(-50%);
  display: flex;
  gap: 12px;
  align-items: center;
  width: fit-content;

  .settings-btn {
    width: calc(var(--cell-size) * 0.26);
    height: calc(var(--cell-size) * 0.26);
    border-radius: 50%;
    background: rgba(60, 103, 227, 0.15);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateY(0);
    position: relative;
    overflow: hidden;
    backdrop-filter: blur(8px);

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      background: linear-gradient(
        var(--rotate, 132deg),
        #5ddcff,
        #3c67e3 43%,
        #4e00c2
      );
      opacity: 0;
      transition: opacity 0.3s ease;
      animation: spin 4s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }

    &:hover {
      background: rgba(60, 103, 227, 0.25);
      transform: translateY(-2px);

      &::before {
        opacity: 0.1;
      }

      i {
        transform: rotate(90deg);
        text-shadow: 0 0 8px rgba(255, 255, 255, 0.5);
      }
    }

    &:active {
      transform: translateY(-1px);
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      transform: none;

      &:hover {
        background: rgba(60, 103, 227, 0.15);
        box-shadow: none;

        &::before {
          opacity: 0;
        }

        i {
          transform: none;
        }
      }
    }

    i {
      font-size: min(22px, calc(var(--cell-size) * 0.22));
      color: white;
      transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      opacity: 0.9;
    }
  }

  button:last-child {
    margin-left: 15px;
    background: rgba(255, 255, 255, 0.15);

    &:hover {
      background: rgba(255, 255, 255, 0.25);
    }
  }
}

.generate-btn {
  position: relative;
  color: white;
  border: none;
  padding: calc(var(--cell-size) * 0.07) calc(var(--cell-size) * 0.24);
  min-width: calc(var(--cell-size));
  border-radius: 8px;
  font-size: min(1.1rem, calc(var(--cell-size) * 0.11));
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  transform: translateY(0);
  background: rgba(60, 103, 227, 0.15);
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(
      var(--rotate, 132deg),
      #5ddcff,
      #3c67e3 43%,
      #4e00c2
    );
    border-radius: inherit;
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    padding: 2px;
    z-index: -1;
  }

  &:hover {
    transform: translateY(-2px);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
    &:hover {
      transform: none;
    }
  }
}

.close-btn {
  width: calc(var(--cell-size) * 0.26);
  height: calc(var(--cell-size) * 0.26);
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.15);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translateY(0);
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(8px);

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(
      var(--rotate, 132deg),
      #5ddcff,
      #3c67e3 43%,
      #4e00c2
    );
    opacity: 0;
    transition: opacity 0.3s ease;
    animation: spin 4s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.25);
    transform: translateY(-2px);

    &::before {
      opacity: 0.1;
    }

    i {
      transform: rotate(90deg);
      text-shadow: 0 0 8px rgba(255, 255, 255, 0.5);
    }
  }

  &:active {
    transform: translateY(-1px);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    transform: none;

    &:hover {
      background: rgba(255, 255, 255, 0.15);
      box-shadow: none;

      &::before {
        opacity: 0;
      }

      i {
        transform: none;
      }
    }
  }

  i {
    font-size: min(22px, calc(var(--cell-size) * 0.22));
    color: white;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0.9;
  }
}

.placeholder-btn {
  width: calc(var(--cell-size) * 0.26);
  height: calc(var(--cell-size) * 0.26);
  visibility: hidden;
  pointer-events: none;
}

.supported-formats {
  position: absolute;
  bottom: 20px;
  color: rgba(255, 255, 255, 0.5);
  font-size: min(0.85rem, calc(var(--cell-size) * 0.085));
  font-weight: 400;
}

.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.fade-slide-enter-from {
  opacity: 0;
  transform: translate(-50%, 40px);
}

.fade-slide-leave-to {
  opacity: 0;
  transform: translate(-50%, -40px);
}

.fade-slide-enter-to,
.fade-slide-leave-from {
  opacity: 1;
  transform: translate(-50%, 0);
}

@keyframes glowPulse {
  0%,
  100% {
    filter: blur(20px);
    opacity: 0.5;
  }
  50% {
    filter: blur(25px);
    opacity: 0.7;
  }
}
</style>
